<template>
  <flushing-detail-template
    :gender="gender"
    :hot-flushes="hotFlushes"
    :flush-locations="flushLocations"
    :flushes-duration="flushesDuration"
    :other-flush-locations="otherFlushLocations"
    :field-errors="fieldErrors"
    :show-menopause-question="showMenopauseQuestion"
    @field-change="onFieldChange"
  />
</template>

<script>
import FlushingDetailTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-redness/flushing-detail/FlushingDetailTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';
import { female } from '@/modules/questionnaire/api/helpers';

import { MENOPAUSAL } from '@/modules/questionnaire/api/constants';

const { field, requiredField, requiredArrayField } = fieldBuilder;

const FIELDS = [
  field('age'),
  field('gender'),
  field('menopausalState'),
  requiredArrayField('flushLocations'),
  requiredField('hotFlushes'),
  requiredField('otherFlushLocations'),
  requiredField('flushesDuration')
];

export default {
  name: 'FlushingDetail',
  components: {
    FlushingDetailTemplate
  },
  mixins: [makeStep(FIELDS)],
  computed: {
    showMenopauseQuestion() {
      return (
        female(this.gender) &&
        Object.values(MENOPAUSAL).includes(this.menopausalState) &&
        this.menopausalState !== MENOPAUSAL.NO
      );
    }
  },
  watch: {
    hotFlushes() {
      this.scrollTo('#flushes-duration');
    },
    flushesDuration() {
      this.scrollTo('#flush-locations');
    }
  },
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'hotFlushes') {
        return this.showMenopauseQuestion;
      }

      if (fieldName === 'otherFlushLocations') {
        return this.flushLocations.includes('other');
      }

      return true;
    }
  }
};
</script>
